import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { myPlanDTO } from "../dto";
// TODO: `{}` はなんでも渡せる型なので、genCallerを修正してRequestを空にできるようにしたい
export var getCanceledMyPlansRequest = z.object({});
export var getCanceledMyPlansResponse = z.object({
    canceledMyPlans: z.array(myPlanDTO)
});
export var callGetCanceledMyPlans = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getCanceledMyPlans");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetCanceledMyPlans(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getCanceledMyPlansKey = function() {
    return {
        key: "getCanceledMyPlans"
    };
};
