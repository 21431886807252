import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { shippingAddressDTO } from "../dto";
import { cartNotificationsDTO } from "../dto/cartNotifications";
import { orderSummaryDTO } from "../dto/orderSummary";
import { prePurchaseGoodsDTO } from "../dto/prePurchaseGoods";
export var getPrePurchaseByCartRequest = z.object({
    cartId: z.string().min(1)
});
var baseSchema = z.object({
    brandSlug: z.string().min(1),
    goodsList: z.array(prePurchaseGoodsDTO),
    cartNotifications: z.array(cartNotificationsDTO),
    orderSummary: orderSummaryDTO,
    konbiniPaymentFee: z.number().nonnegative()
});
export var getPrePurchaseByCartResponse = z.union([
    baseSchema.merge(z.object({
        isShippingRequired: z.literal(true),
        defaultShippingAddress: shippingAddressDTO,
        alternativeShippingAddresses: z.array(shippingAddressDTO)
    })),
    baseSchema.merge(z.object({
        isShippingRequired: z.literal(false)
    })), 
]);
export var callGetPrePurchaseByCart = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getPrePurchaseByCart");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetPrePurchaseByCart(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getPrePurchaseByCartKey = function(query) {
    return _object_spread({
        key: "getPrePurchaseByCart"
    }, query);
};
