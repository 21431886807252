if (!process.env.NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH) {
  throw new Error("NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH is not set");
}
export const NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH =
  process.env.NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH;

if (!process.env.NEXT_PUBLIC_BACKEND_API_URL) {
  throw new Error("NEXT_PUBLIC_BACKEND_API_URL is not set");
}

export const NEXT_PUBLIC_BACKEND_API_URL =
  process.env.NEXT_PUBLIC_BACKEND_API_URL;
