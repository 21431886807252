import { z } from "zod";
export var cartNotificationsStatus = z.union([
    z.literal("soldOut"),
    z.literal("stockShortage"),
    z.literal("outOfSalePeriod"),
    z.literal("priceChanged"), 
]);
export var cartNotificationsDTO = z.object({
    status: cartNotificationsStatus,
    goodsName: z.string(),
    goodsId: z.string()
});
