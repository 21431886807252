import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { getAuth } from "firebase/auth";
var PACKED_BACKEND_ROOT_PATH = process.env.NEXT_PUBLIC_PACKED_BACKEND_ROOT_PATH;
var PACKED_BACKEND_PUBLIC_ROOT_PATH = process.env.NEXT_PUBLIC_PACKED_BACKEND_PUBLIC_ROOT_PATH;
export var genCaller = function(name) {
    return function() {
        var _ref = _async_to_generator(function(functions, request) {
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            fetchWithAuth(name, functions, request)
                        ];
                    case 1:
                        return [
                            2,
                            _state.sent()
                        ];
                }
            });
        });
        return function(functions, request) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var genServerSideFetchApiURL = function() {
    return PACKED_BACKEND_PUBLIC_ROOT_PATH !== null && PACKED_BACKEND_PUBLIC_ROOT_PATH !== void 0 ? PACKED_BACKEND_PUBLIC_ROOT_PATH : "";
};
var endpoint = function(name) {
    if (!PACKED_BACKEND_PUBLIC_ROOT_PATH || !PACKED_BACKEND_ROOT_PATH) {
        throw new Error("env not found");
    }
    return name.startsWith("getPublic") ? PACKED_BACKEND_PUBLIC_ROOT_PATH : PACKED_BACKEND_ROOT_PATH;
};
/**
 * firebase authでの検証が行われる前にfetchを呼びださないようにするためのfetch関数
 */ var fetchWithAuth = function() {
    var _ref = _async_to_generator(function(name, functions, request) {
        var auth;
        return _ts_generator(this, function(_state) {
            auth = getAuth(functions.app);
            return [
                2,
                new Promise(function(resolve, reject) {
                    auth.onAuthStateChanged(function() {
                        var _ref = _async_to_generator(function(authUser) {
                            var idToken, _tmp, res, json, error;
                            return _ts_generator(this, function(_state) {
                                switch(_state.label){
                                    case 0:
                                        _state.trys.push([
                                            0,
                                            6,
                                            ,
                                            7
                                        ]);
                                        if (!authUser) return [
                                            3,
                                            2
                                        ];
                                        return [
                                            4,
                                            authUser.getIdToken()
                                        ];
                                    case 1:
                                        _tmp = _state.sent();
                                        return [
                                            3,
                                            3
                                        ];
                                    case 2:
                                        _tmp = undefined;
                                        _state.label = 3;
                                    case 3:
                                        idToken = _tmp;
                                        return [
                                            4,
                                            fetch("".concat(endpoint(name), "/").concat(name), {
                                                method: "POST",
                                                headers: _object_spread({
                                                    "Content-Type": "application/json"
                                                }, idToken ? {
                                                    Authorization: "Bearer ".concat(idToken)
                                                } : {}),
                                                body: request ? JSON.stringify(request) : null
                                            })
                                        ];
                                    case 4:
                                        res = _state.sent();
                                        if (!res.ok) {
                                            throw new Error("Failed to call packed backend");
                                        }
                                        return [
                                            4,
                                            res.json()
                                        ];
                                    case 5:
                                        json = _state.sent();
                                        resolve(json); // Promiseを解決し、結果を返す
                                        return [
                                            3,
                                            7
                                        ];
                                    case 6:
                                        error = _state.sent();
                                        reject(error); // エラーが発生した場合はPromiseを拒否
                                        return [
                                            3,
                                            7
                                        ];
                                    case 7:
                                        return [
                                            2
                                        ];
                                }
                            });
                        });
                        return function(authUser) {
                            return _ref.apply(this, arguments);
                        };
                    }());
                })
            ];
        });
    });
    return function fetchWithAuth(name, functions, request) {
        return _ref.apply(this, arguments);
    };
}();
