/**
 * page の URL path を扱う
 *
 * pathpidaを利用する
 * - https://github.com/aspida/pathpida
 *
 * memo featureではないかも
 */
import { UrlObject } from "url";

import { useRouter } from "next/router";

import { pagesPath as pagesPathOrig } from "../lib/$path";
import { OptionalQuery as LoginQuery } from "../pages/login";

/**
 * 各featureはpathpidaを lib/$path を直接使用せず、features/path から利用する
 * - lib/$pathは自動生成のためコメントができない
 *
 * FIXME pathpidaを利用して、useRouterやLinkをカスタマイズしてもいいかも
 * - https://zenn.dev/hisho/books/617d8f9d6bd78b/viewer/chapter13
 */
export const pagesPath = pagesPathOrig;

/**
 * pathpidaで生成したurlから実際のpathを取得する
 *
 * - NOTE: pathnameは /brand/[brandSlug] のような値が入っており、実際のurlを示していない
 * - NOTE: https://github.com/aspida/pathpida/pull/124 が混ざったら解消される
 */
export const getAsPath = (url: UrlObject): string => {
  if (url.href) {
    return url.href;
  }

  let result = url.pathname ?? "/";
  const queryList: [string, string][] = url.query
    ? Object.entries(url.query)
        // string以外を除外する (pathpidaで生成されるurlを対象にするので)
        .flatMap(([key, value]) =>
          typeof value === "string" ? [[key, value]] : []
        )
    : [];
  // [query] の部分をqueryオブジェクトで置き換える
  for (const [query, value] of queryList) {
    result = result.replaceAll(`[${query}]`, value);
  }
  return result;
};

/**
 * queryに型をつけて返す
 *
 * - クライアントサイドで呼ばれること前提
 * - optionalにして返すのは、URLはユーザー操作で変更可能なため
 *
 * Note
 * - useRouterのqueryに型をつけるutil（モックアップ）
 *   - pathpidaは基本的にpush（送信側）に対して型安全にするライブラリなので、受信側は別途作成する必要がある
 *   - pathpidaによって、各pagesファイルにクエリパラメータの型定義を作成することになるため、それを利用している
 *   - 自分がどこのURLにいるのかを知っている上で、usePagesQueryを利用する
 *
 * @returns
 */
export const usePagesQuery = () => {
  const { query, isReady } = useRouter();
  if (!isReady) {
    throw new Error(
      "Unexpected Error: useQueryはクライアントサイドで使用される想定です"
    );
  }
  return {
    login: query as Partial<LoginQuery>,
    // 追加イメージ
    // _slug: query as Partial<LoginQuery>,
    // "/": query as Partial<LoginQuery>,
  };
};
