import { z } from "zod";
export var createSessionCookieRequest = z.object({});
// export type CreateSessionCookieRequest = z.infer<
//   typeof createSessionCookieRequest
// >;
export var createSessionCookieResponse = z.void(); // export type CreateSessionCookieResponse = z.infer<
 //   typeof createSessionCookieResponse
 // >;
 /**
 * TODO fetchAPI を利用するfunctionを用意する
 * ただし、呼び出し元がspwnリポジトリになるため、現状直接使用されない
 */ 
