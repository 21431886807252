import { z } from "zod";
import { publicPlanDTO } from "./publicPlan";
import { backNumberPeriod } from "../shared/backNumberPeriod";
/**
 * プランごとのバックナンバー
 *
 * - バックナンバー購入ページで使用する
 */ export var publicPlanBackNumberDTO = z.object({
    /**
   * バックナンバーの元となるプラン
   */ plan: publicPlanDTO,
    /**
   * バックナンバーの期間
   */ period: backNumberPeriod,
    /**
   * ユーザーから見たバックナンバーのステータス
   * - onSale 販売中（バックナンバーは販売されている）
   * - purchased 購入済み（プランバックナンバーは購入されている）
   * - waitingForKonbiniPayment コンビニ決済の支払い待ち（コンビニ決済を選択したが、まだコンビニで支払われていない）
   */ status: z.union([
        z.literal("onSale"),
        z.literal("purchased"),
        z.literal("waitingForKonbiniPayment"), 
    ])
});
