import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { paginationRequest, paginationResponse } from "./pagenation";
import { genCaller } from "./utils";
import { goodsStatus, goodsType, salesType } from "../dto/publicGoods";
// グッズをフィルターするオプション
var deliveryMethod = z.union([
    z.literal("physical"),
    z.literal("digital")
]);
var goodsFilterOption = z.object({
    deliveryMethod: deliveryMethod.nullable(),
    isPurchasableOnly: z.boolean()
});
export var getPublicGoodsListRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    filterOption: goodsFilterOption.nullable(),
    pagination: paginationRequest.optional()
});
export var getPublicGoodsListResponse = z.object({
    goodsList: z.array(z.object({
        goodsId: z.string().min(1),
        name: z.string(),
        price: z.number(),
        goodsStatus: goodsStatus,
        goodsType: goodsType,
        salesType: salesType,
        url: z.string(),
        thumbnail: z.object({
            url: z.string(),
            alt: z.string()
        })
    })),
    pagination: paginationResponse.optional()
});
export var callGetPublicGoodsList = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getPublicGoodsList");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetPublicGoodsList(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getPublicGoodsListKey = function(query) {
    return _object_spread({
        key: "getPublicGoodsList"
    }, query);
};
