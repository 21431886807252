import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var changePaymentMethodRequest = z.object({
    // カスタマーポータル内の戻るボタンを押したときの遷移先
    returnUrl: z.string().nullable()
});
export var changePaymentMethodResponse = z.object({
    customerPortalUrl: z.string()
});
export var callChangePaymentMethod = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("changePaymentMethod");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callChangePaymentMethod(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var changePaymentMethodKey = function() {
    return {
        key: "changePaymentMethod"
    };
};
