import type { OptionalQuery as OptionalQuery0 } from '../pages/[brandSlug]'
import type { OptionalQuery as OptionalQuery1 } from '../pages/[brandSlug]/contents/[planContentId]/backnumbers/[planId]/purchase'
import type { OptionalQuery as OptionalQuery2 } from '../pages/[brandSlug]/goods'
import type { Query as Query3 } from '../pages/[brandSlug]/goods/proceed-to-checkout'
import type { Query as Query4 } from '../pages/[brandSlug]/goods/purchase-complete'
import type { OptionalQuery as OptionalQuery5 } from '../pages/[brandSlug]/proceed-to-checkout'
import type { OptionalQuery as OptionalQuery6 } from '../pages/account/order-history'
import type { OptionalQuery as OptionalQuery7 } from '../pages/account/plans'
import type { OptionalQuery as OptionalQuery8 } from '../pages/login'
import type { Query as Query9 } from '../pages/purchase-complete'

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  _brandSlug: (brandSlug: string | number) => ({
    "contact": {
      "thanks": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/contact/thanks' as const, query: { brandSlug }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/contact' as const, query: { brandSlug }, hash: url?.hash })
    },
    "contents": {
      _planContentId: (planContentId: string | number) => ({
        "backnumbers": {
          _planId: (planId: string | number) => ({
            "purchase": {
              $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ pathname: '/[brandSlug]/contents/[planContentId]/backnumbers/[planId]/purchase' as const, query: { brandSlug, planContentId, planId, ...url?.query }, hash: url?.hash })
            }
          }),
          $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/contents/[planContentId]/backnumbers' as const, query: { brandSlug, planContentId }, hash: url?.hash })
        },
        "plans": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/contents/[planContentId]/plans' as const, query: { brandSlug, planContentId }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/contents/[planContentId]' as const, query: { brandSlug, planContentId }, hash: url?.hash })
      })
    },
    "goods": {
      _goodsId: (goodsId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/goods/[goodsId]' as const, query: { brandSlug, goodsId }, hash: url?.hash })
      }),
      "contact": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/goods/contact' as const, query: { brandSlug }, hash: url?.hash })
      },
      "proceed_to_checkout": {
        $url: (url: { query: Query3, hash?: string }) => ({ pathname: '/[brandSlug]/goods/proceed-to-checkout' as const, query: { brandSlug, ...url.query }, hash: url.hash })
      },
      "purchase_complete": {
        $url: (url: { query: Query4, hash?: string }) => ({ pathname: '/[brandSlug]/goods/purchase-complete' as const, query: { brandSlug, ...url.query }, hash: url.hash })
      },
      $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ pathname: '/[brandSlug]/goods' as const, query: { brandSlug, ...url?.query }, hash: url?.hash })
    },
    "plans": {
      _planId: (planId: string | number) => ({
        "purchase": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/plans/[planId]/purchase' as const, query: { brandSlug, planId }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/[brandSlug]/plans' as const, query: { brandSlug }, hash: url?.hash })
    },
    "proceed_to_checkout": {
      $url: (url?: { query?: OptionalQuery5, hash?: string }) => ({ pathname: '/[brandSlug]/proceed-to-checkout' as const, query: { brandSlug, ...url?.query }, hash: url?.hash })
    },
    $url: (url?: { query?: OptionalQuery0, hash?: string }) => ({ pathname: '/[brandSlug]' as const, query: { brandSlug, ...url?.query }, hash: url?.hash })
  }),
  "about": {
    $url: (url?: { hash?: string }) => ({ pathname: '/about' as const, hash: url?.hash })
  },
  "account": {
    "addresses": {
      _addressId: (addressId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/account/addresses/[addressId]' as const, query: { addressId }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/account/addresses' as const, hash: url?.hash })
    },
    "cart": {
      _cartId: (cartId: string | number) => ({
        "purchase": {
          $url: (url?: { hash?: string }) => ({ pathname: '/account/cart/[cartId]/purchase' as const, query: { cartId }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/account/cart' as const, hash: url?.hash })
    },
    "order_history": {
      _orderId: (orderId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/account/order-history/[orderId]' as const, query: { orderId }, hash: url?.hash })
      }),
      $url: (url?: { query?: OptionalQuery6, hash?: string }) => ({ pathname: '/account/order-history' as const, query: url?.query, hash: url?.hash })
    },
    "plans": {
      _planId: (planId: string | number) => ({
        "cancel": {
          $url: (url?: { hash?: string }) => ({ pathname: '/account/plans/[planId]/cancel' as const, query: { planId }, hash: url?.hash })
        },
        "continue": {
          $url: (url?: { hash?: string }) => ({ pathname: '/account/plans/[planId]/continue' as const, query: { planId }, hash: url?.hash })
        }
      }),
      $url: (url?: { query?: OptionalQuery7, hash?: string }) => ({ pathname: '/account/plans' as const, query: url?.query, hash: url?.hash })
    }
  },
  "login": {
    $url: (url?: { query?: OptionalQuery8, hash?: string }) => ({ pathname: '/login' as const, query: url?.query, hash: url?.hash })
  },
  "purchase_complete": {
    $url: (url: { query: Query9, hash?: string }) => ({ pathname: '/purchase-complete' as const, query: url.query, hash: url.hash })
  },
  "sitemap_xml": {
    $url: (url?: { hash?: string }) => ({ pathname: '/sitemap.xml' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
