import { z } from "zod";
/**
 * コンテンツのメタデータ
 *
 * ページのタイトルや、OGPのために使用する
 */ export var planContentMetadataDTO = z.object({
    /** テナントid */ tenantId: z.string().min(1),
    /** ブランドid */ brandId: z.string().min(1),
    /** コンテンツid */ planContentId: z.string().min(1),
    /** コンテンツ名 */ planContentName: z.string().min(1),
    /** コンテンツのサムネイル (OGP画像として使用) */ planContentThumbnail: z.string().min(1).nullable(),
    /** コンテンツの説明文 (metaのdescriptionとして使用/tiptap JSONのためunknown) */ planContentDescription: z.unknown(),
    /** コンテンツの公開日時 */ publishedAt: z.number().min(1).nullable()
});
