import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { z } from "zod";
/**
 * プランコンテンツDTOの共通部分のオブジェクト
 */ var publicPlanContentBaseObj = {
    /**
   * プランコンテンツID
   */ planContentId: z.string().min(1),
    /**
   * コンテンツ名
   */ name: z.string(),
    /**
   * 説明文
   * tiptapのJSON
   */ description: z.unknown(),
    /**
   * サムネイル画像
   */ thumbnail: z.string(),
    /**
   * ステータス
   * - freeContents 全体公開のコンテンツ
   * - requiredPurchasePlan プラン販売中（プランは販売されている）
   * - available プラン購入済み（プランは購入されている）
   * - unavailable プラン販売終了。（プランを購入していない）
   * - requiredPurchasePlanAndBackNumber バックナンバーパス販売中（プランを購入していない and バックナンバーを購入していない）
   * - requiredPurchaseBackNumber バックナンバーパス販売中（プラン購入している and バックナンバーを購入していない）
   */ status: z.union([
        z.literal("freeContents"),
        z.literal("requiredPurchasePlan"),
        z.literal("available"),
        z.literal("unavailable"),
        z.literal("requiredPurchasePlanAndBackNumber"),
        z.literal("requiredPurchaseBackNumber"), 
    ]),
    /**
   * コンテンツが含まれるプラン
   */ plans: z.array(z.object({
        planId: z.string(),
        name: z.string()
    })),
    /**
   * コンテンツの公開日時
   */ publishedAt: z.number()
};
var publicPlanContentBase = z.object(publicPlanContentBaseObj);
/**
 * いいね数
 */ var countOfLoves = z.number().nonnegative();
/**
 * コメント数
 */ var countOfComments = z.number().nonnegative();
var publicPlanContentVideoDTO = z.object(_object_spread_props(_object_spread({}, publicPlanContentBaseObj), {
    type: z.literal("video"),
    // details: z.object({}),
    countOfLoves: countOfLoves,
    countOfComments: countOfComments
}));
var publicPlanContentNewsDTO = z.object(_object_spread_props(_object_spread({}, publicPlanContentBaseObj), {
    type: z.literal("news"),
    // details: z.object({}),
    countOfLoves: countOfLoves,
    countOfComments: countOfComments
}));
var publicPlanContentStreamingDTO = z.object(_object_spread_props(_object_spread({}, publicPlanContentBaseObj), {
    type: z.literal("streaming"),
    details: z.object({
        streamingStatus: z.union([
            z.literal("beforeLive"),
            z.literal("live"),
            z.literal("beforeVOD"),
            z.literal("vod"),
            z.literal("ended"), 
        ]),
        // 配信開始日時
        startAt: z.number().nullable(),
        // 配信期限
        expiredAt: z.number().nullable()
    })
}));
/**
 * ユーザーがプランコンテンツ一覧ページで取得するプランコンテンツDTO
 * - activeなプランに紐づいていないコンテンツは取得されない
 */ export var publicPlanContentDTO = z.discriminatedUnion("type", [
    publicPlanContentVideoDTO,
    publicPlanContentNewsDTO,
    publicPlanContentStreamingDTO, 
]);
