import { z } from "zod";
import { digital, goodsStatus, goodsType, physical, salesType } from "./publicGoods";
/**
 * カート内でのグッズ情報
 * グッズDTOと多く共通している部分があるが、不要な情報は省く
 */ var baseCartGoodsDTO = z.object({
    goodsId: z.string(),
    name: z.string(),
    price: z.number(),
    goodsStatus: goodsStatus,
    goodsType: goodsType,
    variationId: z.string().min(1),
    variationName: z.string(),
    isSoldOut: z.boolean(),
    maxQuantity: z.number().optional(),
    remainingStocks: z.number().optional(),
    thumbnail: z.object({
        url: z.string(),
        alt: z.string()
    }),
    salesType: salesType,
    saleStartDateTime: z.number()
});
var physicalCartGoodsDTO = z.object({
    goodsType: physical,
    shippingFee: z.number(),
    shippingTimeText: z.string()
});
var digitalContentCartGoodsDTO = z.object({
    goodsType: digital
});
export var cartGoodsDTO = z.discriminatedUnion("goodsType", [
    baseCartGoodsDTO.merge(physicalCartGoodsDTO),
    baseCartGoodsDTO.merge(digitalContentCartGoodsDTO), 
]);
