import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var revertPlanContentToDraftRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    planContentId: z.string().min(1)
});
export var revertPlanContentToDraftResponse = z.void();
export var callRevertPlanContentToDraft = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("revertPlanContentToDraft");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callRevertPlanContentToDraft(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var revertPlanContentToDraftKey = function(query) {
    return _object_spread({
        key: "revertPlanContentToDraft"
    }, query);
};
