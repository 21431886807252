import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { myPlanDTO } from "../dto";
export var getActiveUserPlanRequest = z.object({
    planId: z.string().min(1)
});
export var getActiveUserPlanResponse = z.object({
    userPlan: myPlanDTO.nullable()
});
export var callGetActiveUserPlan = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getActiveUserPlan");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetActiveUserPlan(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getActiveUserPlanKey = function(query) {
    return _object_spread({
        key: "getActiveUserPlan"
    }, query);
};
