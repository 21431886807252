import { z } from "zod";
/**
 * ユーザーがプラン一覧ページで取得するプランDTO
 */ export var publicPlanDTO = z.object({
    /**
   * プランID
   */ planId: z.string().min(1),
    /**
   * プラン名
   */ name: z.string(),
    /**
   * プラン説明文
   * tiptapのJSON
   */ description: z.unknown(),
    /**
   * プランサムネイル画像
   */ thumbnail: z.string(),
    /**
   * 表示ステータス
   * - onSale 販売中（プランは販売されている）
   * - purchased 購入済み（プランは購入されている）
   * - waitingForKonbiniPayment コンビニ決済の支払い待ち（コンビニ決済を選択したが、まだコンビニで支払われていない）
   */ status: z.union([
        z.literal("onSale"),
        z.literal("purchased"),
        z.literal("waitingForKonbiniPayment"), 
    ]),
    /**
   * サブスクの価格群
   * MVPでは月額飲みだが、年額対応にできるようにするためのモデリングになっている
   */ prices: z.array(z.object({
        /**
       * 価格
       */ price: z.number(),
        /**
       * サブスクのインターバル
       * デフォルトは月単位
       */ subscriptionInterval: z.union([
            z.literal("month"),
            z.literal("year")
        ]),
        /**
       * StripeのPriceId
       */ stripePriceId: z.string().min(1)
    })),
    // プラン提供終了予定日（タイムスタンプミリ秒）
    archivingAt: z.number().nullable(),
    // プラン提供が実際に終了された日時（タイムスタンプミリ秒）
    archivedAt: z.number().nullable(),
    /**
   * プランに紐づくactiveなコンテンツ
   */ planContents: z.array(z.object({
        /**
       * プランコンテンツID
       */ planContentId: z.string().min(1),
        /**
       * プランコンテンツ名
       */ name: z.string(),
        /**
       * サムネ画像
       */ thumbnail: z.string()
    }))
});
