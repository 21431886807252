import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { filterOption } from "./getPublicPlanContents";
import { genCaller } from "./utils";
import { publicPlanContentDTO } from "../dto";
import { backNumberPeriod } from "../shared/backNumberPeriod";
export var getPublicPlanBackNumbersRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    filterOption: filterOption.nullable()
});
export var getPublicPlanBackNumbersResponse = z.array(z.object({
    backNumberPeriod: backNumberPeriod,
    planContents: z.array(publicPlanContentDTO)
}));
export var callGetBrandPlanBackNumberList = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getPublicPlanBackNumbers");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetBrandPlanBackNumberList(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getPublicPlanBackNumbersKey = function(query) {
    return _object_spread({
        key: "getPublicPlanBackNumbers"
    }, query);
};
