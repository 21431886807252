import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var getEmbedBlockDataRequest = z.object({
    tenantId: z.string(),
    brandId: z.string(),
    source: z.string().url()
});
export var getEmbedBlockDataResponse = z.object({
    html: z.string(),
    embedKey: z.union([
        z.string(),
        z.null()
    ]),
    embedProvider: z.union([
        z.string(),
        z.null()
    ])
});
export var callGetEmbedBlockData = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getEmbedBlockData");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetEmbedBlockData(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getEmbedBlockDataKey = function(query) {
    return _object_spread({
        key: "getEmbedBlockData"
    }, query);
};
