import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { cartDTO } from "../dto/cart";
export var getCartsRequest = z.void();
export var getCartsResponse = z.object({
    carts: z.array(cartDTO)
});
export var callGetCarts = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getCarts");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetCarts(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getCartsKey = function() {
    return {
        key: "getCarts"
    };
};
