import { z } from "zod";
export var planDTO = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    planId: z.string().min(1),
    // FIXME: nameとpriceは下書きの時必須じゃなくて良いかも
    name: z.string(),
    price: z.number(),
    description: z.unknown(),
    /**
   * - "active": 販売中
   * - "draft": 下書き
   * - "scheduleToBeArchived": 提供終了予定（購入可能）
   * - "archived": 提供終了
   */ status: z.union([
        z.literal("active"),
        z.literal("draft"),
        z.literal("scheduledToBeArchived"),
        z.literal("archived"), 
    ]),
    archivingAt: z.number().nullable(),
    editedAt: z.number(),
    thumbnail: z.string().min(1).nullable()
});
