import { z } from "zod";
/**
 * プランコンテンツコメント DTO
 */ export var planContentCommentDTO = z.object({
    id: z.string().min(1),
    content: z.string(),
    commentedBy: z.object({
        userId: z.string().min(1),
        displayName: z.string().nullable(),
        icon: z.string().nullable()
    }),
    commentedAt: z.number(),
    canDelete: z.boolean()
});
