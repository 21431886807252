import { z } from "zod";
/**
 * グッズの種類
 * physicalGoods: 配送が必要な販売グッズ
 * digitalGoods: ダウンロード販売するグッズ
 */ export var physical = z.literal("physicalGoods");
export var digital = z.literal("digitalGoods");
export var goodsType = z.union([
    physical,
    digital
]);
/**
 * 販売の種類
 * permanent: 常設販売グッズ
 * builtToOrder: 受注販売グッズ
 */ export var salesType = z.union([
    z.literal("permanent"),
    z.literal("builtToOrder"), 
]);
/**
 * visibility: 表示状態
 * salesStatus: 販売状況
 */ export var goodsStatus = z.union([
    z.object({
        visibility: z.literal("visible"),
        salesStatus: z.literal("beforeSale")
    }),
    z.object({
        visibility: z.literal("visible"),
        salesStatus: z.literal("onSale")
    }),
    z.object({
        visibility: z.literal("visible"),
        salesStatus: z.literal("soldOut")
    }),
    z.object({
        visibility: z.literal("visible"),
        salesStatus: z.literal("endOfSale")
    }), 
]);
var baseGoodsDTO = z.object({
    goodsId: z.string(),
    name: z.string(),
    price: z.number(),
    goodsStatus: goodsStatus,
    goodsType: goodsType,
    variations: z.array(z.object({
        variationId: z.string().min(1),
        variationName: z.string(),
        isSoldOut: z.boolean(),
        maxQuantity: z.number().optional()
    })),
    thumbnails: z.array(z.object({
        url: z.string(),
        alt: z.string()
    })),
    description: z.string(),
    salesType: salesType,
    saleStartDateTime: z.number()
});
var physicalGoodsDTO = z.object({
    goodsType: physical,
    shippingFee: z.number(),
    shippingTimeText: z.string()
});
var digitalContentGoodsDTO = z.object({
    goodsType: digital,
    purchaseInfo: z.union([
        z.object({
            downloadUrl: z.string(),
            isPurchased: z.literal(true)
        }),
        z.object({
            isPurchased: z.literal(false)
        }), 
    ])
});
export var publicGoodsDTO = z.discriminatedUnion("goodsType", [
    baseGoodsDTO.merge(physicalGoodsDTO),
    baseGoodsDTO.merge(digitalContentGoodsDTO), 
]);
