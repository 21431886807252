/**
 * fetch API utility
 * 主に onRequest function を利用する
 * 基本的に functions は onCall function を使用するが、
 * 一部 cookie などを取り扱いたい function に関しては onRequest を利用する
 *
 * 実装参考 https://zukucode.com/2022/09/typescript-fetch.html
 * - エラーの型は用意していない
 */ import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
/**
 * POSTリクエスト
 * @param url
 * @param body
 * @param options
 * @returns
 */ export var post = function() {
    var _ref = _async_to_generator(function(url, body, options) {
        var response, data, ref, errorMessage;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        fetch(url, _object_spread({
                            method: "POST",
                            headers: _object_spread({
                                "Content-Type": "application/json"
                            }, (options === null || options === void 0 ? void 0 : options.bearerToken) ? {
                                Authorization: "Bearer ".concat(options.bearerToken)
                            } : {}),
                            body: JSON.stringify(body)
                        }, (options === null || options === void 0 ? void 0 : options.credentials) ? {
                            credentials: options.credentials
                        } : {}))
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        4,
                        response.json()
                    ];
                case 2:
                    data = _state.sent();
                    // 成功
                    if (response.ok) {
                        return [
                            2,
                            {
                                ok: true,
                                data: data
                            }
                        ];
                    }
                    errorMessage = (ref = data === null || data === void 0 ? void 0 : data.message) !== null && ref !== void 0 ? ref : response.statusText;
                    return [
                        2,
                        {
                            ok: false,
                            data: {
                                message: errorMessage,
                                code: data === null || data === void 0 ? void 0 : data.code
                            }
                        }
                    ];
            }
        });
    });
    return function post(url, body, options) {
        return _ref.apply(this, arguments);
    };
}();
