import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { backNumberPeriod } from "../shared/backNumberPeriod";
export var createActivePlanContentNewsRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    value: z.object({
        name: z.string().min(1).max(80),
        imageUrls: z.array(z.string()),
        thumbnail: z.string(),
        contentText: z.unknown(),
        visibility: z.discriminatedUnion("status", [
            z.object({
                status: z.literal("allUsers")
            }),
            z.object({
                /**
         * プランの定期購入者に公開
         */ status: z.literal("paidUsers"),
                /**
         * 公開先プランID
         */ planIds: z.array(z.string().min(1)).nonempty(),
                /**
         * バックナンバー期間
         */ backNumberPeriod: backNumberPeriod.nullable()
            }), 
        ])
    })
});
export var createActivePlanContentNewsResponse = z.object({
    planContentId: z.string().min(1)
});
export var callCreateActivePlanContentNews = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("createActivePlanContentNews");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callCreateActivePlanContentNews(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var createActivePlanContentNewsKey = function(query) {
    return _object_spread({
        key: "createActivePlanContentNews"
    }, query);
};
