import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { z } from "zod";
// FIXME: statusごとに定義したいがdiscriminatedUnionのネストできないかも
// @see https://github.com/colinhacks/zod/issues/1884
var planContentBaseObj = {
    planContentId: z.string().min(1),
    name: z.string(),
    thumbnail: z.string(),
    status: z.union([
        z.literal("active"),
        z.literal("draft"),
        z.literal("publishmentScheduled"), 
    ]),
    viewableBy: z.union([
        z.literal("allUsers"),
        z.literal("paidUsers")
    ]),
    planIds: z.array(z.string()),
    backNumberPeriod: z.object({
        year: z.number().positive(),
        month: z.number().min(1).max(12)
    }).nullable(),
    publishingAt: z.number().positive().nullable()
};
var planContentBase = z.object(planContentBaseObj);
export var planContentVideoDTO = z.object(_object_spread_props(_object_spread({}, planContentBaseObj), {
    type: z.literal("video"),
    details: z.object({
        videoUrl: z.string().url(),
        /** コンテンツ本文 */ text: z.unknown(),
        signedCookies: z.array(z.string().min(1)),
        mediaId: z.string().nullable()
    })
}));
export var planContentNewsDTO = z.object(_object_spread_props(_object_spread({}, planContentBaseObj), {
    type: z.literal("news"),
    details: z.object({
        imageUrls: z.array(z.string().url()),
        /** コンテンツ本文 */ text: z.unknown()
    })
}));
export var planContentStreamingDTO = z.object(_object_spread_props(_object_spread({}, planContentBaseObj), {
    type: z.literal("streaming"),
    details: z.object({
        url: z.string().url(),
        text: z.unknown(),
        startAt: z.number(),
        expiredAt: z.number().nullable()
    })
}));
export var planContentDTO = z.discriminatedUnion("type", [
    planContentVideoDTO,
    planContentNewsDTO,
    planContentStreamingDTO, 
]);
