import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var getSignedUrlForPlanContentAttachmentUploadRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    type: z.union([
        z.literal("image"),
        z.literal("audio"),
        z.literal("file")
    ]),
    fileName: z.string().min(1)
});
export var getSignedUrlForPlanContentAttachmentUploadResponse = z.object({
    mediaId: z.string(),
    headers: z.object({
        "Content-Type": z.string(),
        "Content-Disposition": z.string()
    }).partial(),
    urlForUpload: z.string(),
    urlForDownload: z.string()
});
export var callGetSignedUrlForPlanContentAttachmentUpload = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getSignedUrlForPlanContentAttachmentUpload");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetSignedUrlForPlanContentAttachmentUpload(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getSignedUrlForPlanContentAttachmentUploadKey = function(query) {
    return _object_spread({
        key: "getSignedUrlForPlanContentAttachmentUpload"
    }, query);
};
