import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { planContentVisibility } from "../shared/visibility";
export var updatePlanContentStreamingAsDraftRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    planContentId: z.string().min(1),
    value: z.object({
        expiredAt: z.union([
            z.number(),
            z.literal("indefinite")
        ]),
        visibility: planContentVisibility
    }).partial()
});
export var updatePlanContentStreamingAsDraftResponse = z.void();
export var callUpdatePlanContentStreamingAsDraft = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("updatePlanContentStreamingAsDraft");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callUpdatePlanContentStreamingAsDraft(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var updatePlanContentStreamingAsDraftKey = function(query) {
    return _object_spread({
        key: "updatePlanContentStreamingAsDraft"
    }, query);
};
