import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { post } from "./utils/fetchAPI";
export var logoutRequest = z.object({});
export var logoutResponse = z.object({});
export var callLogout = function() {
    var _ref = _async_to_generator(function(backendApiUrl, auth, request) {
        var ref, idToken, url, response;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        (ref = auth.currentUser) === null || ref === void 0 ? void 0 : ref.getIdToken()
                    ];
                case 1:
                    idToken = _state.sent();
                    url = "".concat(backendApiUrl, "/account/spwn/logout");
                    return [
                        4,
                        post(url, request, _object_spread({}, idToken && {
                            bearerToken: idToken
                        }))
                    ];
                case 2:
                    response = _state.sent();
                    return [
                        2,
                        response.ok ? _object_spread_props(_object_spread({}, response), {
                            data: response.data
                        }) : response
                    ];
            }
        });
    });
    return function callLogout(backendApiUrl, auth, request) {
        return _ref.apply(this, arguments);
    };
}();
