import { z } from "zod";
/**
 * バックナンバーの期間
 */ export var backNumberPeriod = z.object({
    /**
   * 年
   */ year: z.number(),
    /**
   * 月
   */ month: z.number().min(1).max(12)
});
