import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { cartDTO } from "../dto/cart";
// TODO: 全てのカート単位で更新を行う or カート単位にする or 商品単位にする どれがいいか検討する
export var updateCartRequest = z.object({
    cartId: z.string().min(1),
    goodsList: z.array(z.object({
        goodsId: z.string().min(1),
        quantity: z.number().int().nonnegative()
    }))
});
export var updateCartResponse = z.object({
    cart: cartDTO,
    errors: z.array(z.object({
        goodsId: z.string().min(1),
        error: z.string().min(1)
    }))
});
export var callUpdateCart = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("updateCart");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callUpdateCart(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var updateCartKey = function(query) {
    return _object_spread({
        key: "updateCart"
    }, query);
};
