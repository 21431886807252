import { z } from "zod";
import { backNumberPeriod } from "./backNumberPeriod";
export var draftPlanContentVisibility = z.discriminatedUnion("status", [
    z.object({
        /**
     * 全体公開（ログインユーザーのみ）
     */ status: z.literal("allUsers")
    }),
    z.object({
        /**
     * プランの定期購入者に公開
     */ status: z.literal("paidUsers"),
        /**
     * 公開先プランID
     */ planIds: z.array(z.string().min(1)),
        /**
     * バックナンバー期間
     */ backNumberPeriod: backNumberPeriod.nullable()
    }), 
]);
export var activePlanContentVisibility = z.discriminatedUnion("status", [
    z.object({
        status: z.literal("allUsers")
    }),
    z.object({
        /**
     * プランの定期購入者に公開
     */ status: z.literal("paidUsers"),
        /**
     * 公開先プランID
     */ planIds: z.array(z.string().min(1)).nonempty(),
        /**
     * バックナンバー期間
     */ backNumberPeriod: backNumberPeriod.nullable()
    }), 
]);
export var planContentVisibility = z.union([
    draftPlanContentVisibility,
    activePlanContentVisibility, 
]);
