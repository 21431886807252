import { z } from "zod";
export var planContentWithPlansDTO = z.object({
    planContentId: z.string().min(1),
    tenantId: z.string().min(1),
    planContentName: z.string(),
    plans: z.array(z.object({
        planId: z.string().min(1),
        name: z.string().min(1)
    })),
    status: z.union([
        z.literal("active"),
        z.literal("draft"),
        z.literal("publishmentScheduled"), 
    ]),
    type: z.union([
        z.literal("video"),
        z.literal("news"),
        z.literal("streaming"), 
    ]),
    editedAt: z.number(),
    publishedAt: z.number().nullable(),
    publishingAt: z.number().nullable(),
    viewableBy: z.union([
        z.literal("allUsers"),
        z.literal("paidUsers")
    ])
});
