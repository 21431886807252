import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { planContentVisibility } from "../shared/visibility";
export var scheduleToPublishPlanContentStreamingRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    planContentId: z.string().min(1),
    value: z.union([
        z.object({
            expiredAt: z.union([
                z.number(),
                z.literal("indefinite")
            ]),
            visibility: planContentVisibility
        }).partial(),
        z.undefined(), 
    ]),
    publishingAt: z.number().positive()
});
export var scheduleToPublishPlanContentStreamingResponse = z.void();
export var callScheduleToPublishPlanContentStreaming = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("scheduleToPublishPlanContentStreaming");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callScheduleToPublishPlanContentStreaming(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var scheduleToPublishPlanContentStreamingKey = function(query) {
    return _object_spread({
        key: "scheduleToPublishPlanContentStreaming"
    }, query);
};
