import { z } from "zod";
export var fanClubOrderHistoryItemDTO = z.object({
    orderId: z.string().min(1),
    /**
   * 決済内容
   */ items: z.array(z.object({
        /**
       * 名前
       */ name: z.string().min(1)
    })),
    /**
   * 購入日時
   */ purchasedAt: z.number().nullable(),
    /**
   * ブランド名
   */ brandName: z.string().min(1),
    /**
   * 支払い種別
   * - payment 買い切り
   * - subscription サブスク
   */ paymentMode: z.union([
        z.literal("payment"),
        z.literal("subscription")
    ]),
    /**
   * オーダーの支払いステータス
   * - active ... 有効なオーダー
   * - incomplete ... 支払い待ちなど手続きが完了していない状態
   * - canceled ... キャンセル（取り消し）された状態
   * - refunded ... 返金された状態
   */ status: z.union([
        z.literal("active"),
        z.literal("incomplete"),
        z.literal("canceled"),
        z.literal("refunded"), 
    ]),
    /**
   * 合計金額
   */ totalPrice: z.number(),
    /**
   * 注文詳細で確認できるOrderかどうか
   * 買い切りの場合のみ存在
   */ hasOrderDetail: z.boolean().optional()
});
