import { z } from "zod";
// グッズのメタデータ
// ページのタイトルや、OGPのために使用する
export var goodsMetadataDTO = z.object({
    // グッズid
    goodsId: z.string().min(1),
    // グッズ名
    goodsName: z.string().min(1),
    // グッズのサムネイル (OGP画像として使用)
    goodsThumbnail: z.string().min(1).nullable(),
    // グッズの説明文 (metaのdescriptionとして使用/tiptap JSONのためunknown)
    goodsDescription: z.unknown()
});
