import { z } from "zod";
// 共通ページネーション
export var paginationRequest = z.object({
    limit: z.number().min(1),
    offset: z.number().min(0)
});
export var paginationResponse = z.object({
    totalPage: z.number().min(1),
    perPage: z.number().min(1),
    currentPage: z.number().min(1)
});
