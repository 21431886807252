import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
import { publicPlanContentDTO } from "../dto";
/**
 * コンテンツをフィルターするオプション
 */ export var filterOption = z.discriminatedUnion("type", [
    z.object({
        type: z.literal("allContents")
    }),
    z.object({
        type: z.literal("freeContents")
    }),
    z.object({
        type: z.literal("planContents"),
        planId: z.string().min(1)
    }), 
]);
export var getPublicPlanContentsRequest = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    filterOption: filterOption.nullable()
});
export var getPublicPlanContentsResponse = z.object({
    tenantId: z.string().min(1),
    brandId: z.string().min(1),
    planContents: z.array(publicPlanContentDTO)
});
export var callGetPublicPlanContents = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getPublicPlanContents");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetPublicPlanContents(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getPublicPlanContentsKey = function(query) {
    return _object_spread({
        key: "getPublicPlanContents"
    }, query);
};
