import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { post } from "./utils/fetchAPI";
var FUNC_NAME = "verifySessionCookie";
export var verifySessionCookieRequest = z.object({});
export var verifySessionCookieResponse = z.object({
    customToken: z.string().min(1)
});
export var getVerifySessionCookieKey = function() {
    return {
        key: FUNC_NAME
    };
};
/**
 * cookieにセットされたセッションcookieからカスタムトークンを取得する
 * - createSessionCookie function によってcookieがセットされている前提で呼ばれる
 *   - カスタムトークンを取得できない場合はエラーとなり、ログインアプリケーションでログインを要求される
 *
 * @example
 * const response = await callVerifySessionCookie(process.env.NEXT_PUBLIC_BACKEND_API_URL, {});
 * if (response.ok) {
 *   return response.data;
 * }
 * throw new Error(response.data.message);
 * @param backendApiUrl
 * @param request
 * @returns
 */ export var callVerifySessionCookie = function() {
    var _ref = _async_to_generator(function(backendApiUrl) {
        var request, url, response;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    request = _arguments.length > 1 && _arguments[1] !== void 0 ? _arguments[1] : {};
                    url = "".concat(backendApiUrl, "/account/spwn/").concat(FUNC_NAME);
                    return [
                        4,
                        post(url, request, {
                            /**
     * cookieのやり取りを行えるようにする
     * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#sending_a_request_with_credentials_included
     */ credentials: "include"
                        })
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        2,
                        response.ok ? _object_spread_props(_object_spread({}, response), {
                            data: response.data
                        }) : response
                    ];
            }
        });
    });
    return function callVerifySessionCookie(backendApiUrl) {
        return _ref.apply(this, arguments);
    };
}();
