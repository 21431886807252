import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { post } from "./utils/fetchAPI";
export var getFanclubSitemapInfoRequest = z.object({});
export var getFanclubSitemapInfoResponse = z.object({
    data: z.object({
        loc: z.string(),
        lastModified: z.string().optional(),
        changefreq: z.union([
            z.literal("always"),
            z.literal("hourly"),
            z.literal("daily"),
            z.literal("weekly"),
            z.literal("monthly"),
            z.literal("yearly"),
            z.literal("never"), 
        ]),
        priority: z.number().positive().max(1)
    }).array()
}).nullable();
export var GET_FANCLUB_SITEMAP_INFO_FUNCTION_NAME = "getFanclubSitemapInfo";
export var callGetFanclubSitemapInfo = function() {
    var _ref = _async_to_generator(function(endpoint, request) {
        var response;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        post("".concat(endpoint, "/").concat(GET_FANCLUB_SITEMAP_INFO_FUNCTION_NAME), request)
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        2,
                        response.ok ? response.data : null
                    ];
            }
        });
    });
    return function callGetFanclubSitemapInfo(endpoint, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getFanclubSitemapInfoKey = function(query) {
    return _object_spread({
        key: "getFanclubSitemapInfo"
    }, query);
};
