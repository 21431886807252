import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { post } from "./utils/fetchAPI";
import { goodsMetadataDTO } from "../dto";
export var getGoodsMetadataByIdRequest = z.object({
    goodsId: z.string().min(1)
});
export var getGoodsMetadataByIdResponse = goodsMetadataDTO.nullable();
export var GET_GOODS_METADATA_BY_Id_FUNCTION_NAME = "getGoodsMetadataById";
export var callGetGoodsMetadataById = function() {
    var _ref = _async_to_generator(function(endpoint, request) {
        var response;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        post("".concat(endpoint, "/").concat(GET_GOODS_METADATA_BY_Id_FUNCTION_NAME), request)
                    ];
                case 1:
                    response = _state.sent();
                    return [
                        2,
                        response.ok ? response.data : null
                    ];
            }
        });
    });
    return function callGetGoodsMetadataById(endpoint, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getGoodsMetadataByIdKey = function(query) {
    return _object_spread({
        key: "getGoodsMetadataById"
    }, query);
};
