import { z } from "zod";
import { cartGoodsDTO } from "./cartGoods";
import { cartNotificationsDTO } from "./cartNotifications";
import { orderSummaryDTO } from "./orderSummary";
export var cartDTO = z.object({
    cartId: z.string().min(1),
    brandSlug: z.string().min(1),
    goodsList: z.array(z.object({
        goods: cartGoodsDTO,
        quantity: z.number()
    })),
    cartNotifications: z.array(cartNotificationsDTO),
    orderSummary: orderSummaryDTO
});
