import { z } from "zod";
/**
 * ブランドのメタデータ
 *
 * ページのタイトルや、OGPのために使用する
 */ export var brandMetadataDTO = z.object({
    /** テナントid */ tenantId: z.string().min(1),
    /** ブランドid */ brandId: z.string().min(1),
    /** ブランドのslug */ brandSlug: z.string().min(1),
    /** ブランド名 */ brandName: z.string().min(1),
    /** ブランドのサムネイル (OGP画像として使用) */ brandThumbnail: z.string().min(1).nullable(),
    /** ブランドの説明文 (metaのdescriptionとして使用/tiptap JSONのためunknown) */ brandDescription: z.unknown()
});
