import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { z } from "zod";
/**
 * プランコンテンツの共通部分のオブジェクト
 */ var planContentBaseObj = {
    planContentId: z.string().min(1),
    tenantId: z.string().min(1),
    brand: z.object({
        name: z.string(),
        slug: z.string(),
        iconThumbnail: z.string().nullable(),
        coverThumbnail: z.string().nullable()
    }),
    planContentName: z.string(),
    planContentDescription: z.unknown(),
    visibility: z.discriminatedUnion("status", [
        z.object({
            status: z.literal("allUsers")
        }),
        z.object({
            status: z.literal("paidUsers")
        }), 
    ]),
    publishedAt: z.number().nullable()
};
/**
 * DTO そのものではないが、converter で参照するために export する
 * main/apps/backend/src/useCases/shared/convertToPlanContentDTO.ts
 */ var planContentBase = z.object(planContentBaseObj);
// いいね数
var countOfLoves = z.number().nonnegative();
// コメント数
var countOfComments = z.number().nonnegative();
// いいね済かどうか (このDTOには含めない方がいいかも)
var hasAlreadyLoved = z.boolean().default(false);
/**
 * ビデオコンテンツ DTO
 */ export var planContentVideoDTO = z.object(_object_spread_props(_object_spread({}, planContentBaseObj), {
    type: z.literal("video"),
    details: z.object({
        videoURL: z.string().min(1),
        videoThumbnail: z.string(),
        signedCookies: z.array(z.string().min(1)),
        /** コンテンツ本文 */ text: z.unknown()
    }),
    hasAlreadyLoved: hasAlreadyLoved,
    countOfLoves: countOfLoves,
    countOfComments: countOfComments
}));
/**
 * ニュースコンテンツ DTO
 */ export var planContentNewsDTO = z.object(_object_spread_props(_object_spread({}, planContentBaseObj), {
    type: z.literal("news"),
    details: z.object({
        imageUrls: z.array(z.string().min(1)),
        text: z.unknown()
    }),
    hasAlreadyLoved: hasAlreadyLoved,
    countOfLoves: countOfLoves,
    countOfComments: countOfComments
}));
/**
 * 配信動画の署名付き Cookie
 */ export var signedCookie = z.object({
    "CloudFront-Key-Pair-Id": z.string(),
    "CloudFront-Policy": z.string(),
    "CloudFront-Signature": z.string()
});
/**
 * 配信 Cookie
 */ var cookie = z.object({
    cookie: signedCookie,
    cors: z.boolean(),
    url: z.string(),
    liveOffset: z.number().optional()
});
/**
 * ストリーミング DTO
 * // NOTE:
 * こちらの変更をした時、legacy/packages/types/functions/crewPlanContent も変更する必要があるかもしれないので注意
 */ export var planContentStreamingDTO = z.object(_object_spread_props(_object_spread({}, planContentBaseObj), {
    type: z.literal("streaming"),
    details: z.object({
        eventId: z.string().min(1),
        streamingId: z.string().min(1),
        // 配信URL
        streamingPageUrl: z.string().min(1),
        streamingSource: z.object({
            default: cookie,
            // LowLatency 大規模配信でのみある
            LL: cookie.optional()
        }),
        // 配信期限
        // TODO planContent 側には配信期限はなくて良いか？
        expiredAt: z.number().nullable(),
        // 配信ステータス
        streamingStatus: z.union([
            z.literal("beforeLive"),
            z.literal("live"),
            z.literal("beforeVOD"),
            z.literal("vod"),
            z.literal("ended"), 
        ])
    })
}));
/**
 * プランコンテンツ DTO
 */ export var planContentDTO = z.discriminatedUnion("type", [
    planContentVideoDTO,
    planContentNewsDTO,
    planContentStreamingDTO, 
]);
