import { z } from "zod";
/**
 * ユーザーが契約している・過去契約していたプラン
 * @see https://www.notion.so/balusco/6b40253bb8b04181ba5b610678b2343a#7d7bd27ce6c04c10982afe64ff2fe3ff
 */ export var myPlanDTO = z.object({
    /** プランのid */ planId: z.string().min(1),
    /** プラン名 */ name: z.string().min(1),
    /** プランの価格 */ price: z.number(),
    /** プランの更新 */ subscriptionInterval: z.union([
        z.literal("month"),
        z.literal("year")
    ]),
    /** プランのサムネイル */ thumbnail: z.string().min(1).nullable(),
    /** プランの説明文 */ description: z.unknown(),
    /** プランのブランド */ brand: z.object({
        id: z.string().min(1),
        name: z.string().min(1),
        icon: z.string().min(1).nullable(),
        slug: z.string().min(1)
    }),
    /**
   * マイプランのステータス
   */ status: z.union([
        z.object({
            /** 契約中 */ type: z.literal("active"),
            /** 購入日 */ purchasedAt: z.number(),
            /** 契約期限 */ expiredAt: z.number()
        }),
        z.object({
            /** 解約予定 */ type: z.literal("scheduledToCancel"),
            /** 購入日 */ purchasedAt: z.number(),
            /** 解約申請日 (管理画面からの解約の場合nullになる可能性あり) */ cancelAppliedAt: z.number().nullable(),
            /** 解約予定日 */ cancelScheduledAt: z.number()
        }),
        z.object({
            /** 解約済み */ type: z.literal("canceled"),
            /** 購入日 */ purchasedAt: z.number(),
            /** 解約申請日 (管理画面からの解約の場合nullになる可能性あり) */ cancelAppliedAt: z.number().nullable(),
            /** 解約日 */ canceledAt: z.number()
        }),
        z.object({
            /** 提供終了予定 */ type: z.literal("scheduledToArchive"),
            /** 購入日 */ purchasedAt: z.number(),
            /** 提供終了予定日 */ archiveScheduledAt: z.number(),
            /** 契約期限 */ expiredAt: z.number()
        }),
        z.object({
            /** 提供終了 */ type: z.literal("archived"),
            /** 購入日 */ purchasedAt: z.number(),
            /** 提供終了日 */ archivedAt: z.number()
        }),
        z.object({
            /** 支払い未完了 */ type: z.literal("paymentIncomplete"),
            /** 購入日 */ purchasedAt: z.number(),
            /** 支払い期限 */ expiredAt: z.number()
        }),
        z.object({
            /** コンビニで支払いが必要 */ type: z.literal("requiredKonbiniPayment"),
            /** 購入日 */ purchasedAt: z.number(),
            /** マイプランに紐づく最も新しいオーダーの ID */ latestOrderId: z.string().min(1)
        }), 
    ])
});
