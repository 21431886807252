import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var getOrderReceiptUrlRequest = z.object({
    orderId: z.string()
});
export var getOrderReceiptUrlResponse = z.object({
    receiptUrl: z.string()
});
export var callGetOrderReceiptUrl = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getOrderReceiptUrl");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetOrderReceiptUrl(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getOrderReceiptUrlKey = function(query) {
    return _object_spread({
        key: "getOrderReceiptUrl"
    }, query);
};
