import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var getPlanContentVideoConversionStatusRequest = z.object({
    tenantId: z.string(),
    brandId: z.string(),
    mediaId: z.string()
});
export var getPlanContentVideoConversionStatusResponse = z.union([
    z.literal("progressing"),
    z.literal("completed"),
    z.literal("error"),
    z.literal("canceled"), 
]).nullable();
export var callGetPlanContentVideoConversionStatus = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("getPlanContentVideoConversionStatus");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callGetPlanContentVideoConversionStatus(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
export var getPlanContentVideoConversionStatusKey = function(query) {
    return _object_spread({
        key: "getPlanContentVideoConversionStatus"
    }, query);
};
