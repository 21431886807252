import { z } from "zod";
import { orderSummaryDTO } from "./orderSummary";
import { shippingAddressDTO } from "./shippingAddress";
var orderedGoodsDTO = z.object({
    id: z.string().min(1),
    bransSlug: z.string().min(1),
    name: z.string(),
    price: z.number(),
    thumbnail: z.object({
        src: z.string(),
        alt: z.string()
    }),
    deliveryInfo: z.union([
        z.object({
            deliveryMethod: z.literal("physical"),
            deliveryTimeText: z.string()
        }),
        z.object({
            deliveryMethod: z.literal("digital"),
            downloadUrl: z.string()
        }), 
    ]),
    variation: z.object({
        id: z.string().min(1),
        name: z.string()
    }),
    quantity: z.number().min(1)
});
var paymentInfoDTO = z.object({
    paymentMethod: z.string(),
    paymentNumber: z.string().optional()
});
var shippingProviderInfoDTO = z.object({
    // 発送業者の名前
    providerName: z.string(),
    // 追跡番号
    trackingNumber: z.string(),
    // 追跡外部URL
    trackingUrl: z.string(),
    // 発送状況
    shippingStatus: z.union([
        z.literal("preparing"),
        z.literal("shipping"),
        z.literal("shipped"),
        z.literal("delivered")
    ])
});
export var orderDTO = z.object({
    orderId: z.string(),
    orderedGoods: z.array(orderedGoodsDTO),
    shippingAddress: shippingAddressDTO,
    paymentInfo: paymentInfoDTO,
    shippingProviderInfo: shippingProviderInfoDTO,
    orderSummary: orderSummaryDTO
});
