import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { z } from "zod";
import { genCaller } from "./utils";
export var purchasePlanSubscriptionRequest = z.object({
    tenantId: z.string().min(1),
    planId: z.string().min(1),
    /** 決済ページから戻るページの相対パス */ returnPath: z.string().min(1)
});
export var purchasePlanSubscriptionResponse = z.object({
    sessionUrl: z.string()
});
export var callPurchasePlanSubscription = function() {
    var _ref = _async_to_generator(function(functions, request) {
        var call, data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    call = genCaller("purchasePlanSubscription");
                    return [
                        4,
                        call(functions, request)
                    ];
                case 1:
                    data = _state.sent();
                    return [
                        2,
                        data
                    ];
            }
        });
    });
    return function callPurchasePlanSubscription(functions, request) {
        return _ref.apply(this, arguments);
    };
}();
